import { createSlice } from '@reduxjs/toolkit';
import * as ordersThunks from './ordersThunk';
import * as orderDetailsThunks from './orderDetailsThunk';

const initialState = {
  orders: [],
  orderDetails: [],
  currentOrder: null,
  currentOrderDetail: null,
  status: 'idle',
  error: null,
  pagination: {
    total: 0,
    page: 1,
    maxRecords: 10,
    totalPages: 0,
  },
};

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetState: () => initialState,
    clearCurrentOrder: (state) => {
      state.currentOrder = null;
    },
    clearCurrentOrderDetail: (state) => {
      state.currentOrderDetail = null;
    },
    updatePagination: (state, action) => {
      state.pagination = { ...state.pagination, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // Orders reducers
      .addCase(ordersThunks.fetchOrders.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(ordersThunks.fetchOrders.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orders = action.payload.orders;

        if (action.payload.pagination) {
          state.pagination = {
            total: action.payload.pagination.total,
            page: action.payload.pagination.page,
            maxRecords: action.payload.pagination.maxRecords,
            totalPages: action.payload.pagination.totalPages,
          };
        }
      })
      .addCase(ordersThunks.fetchOrders.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      .addCase(ordersThunks.fetchOrderById.fulfilled, (state, action) => {
        state.currentOrder = action.payload;
      })

      .addCase(ordersThunks.createNewOrder.fulfilled, (state, action) => {
        state.orders.push(action.payload);
      })

      .addCase(ordersThunks.updateExistingOrder.fulfilled, (state, action) => {
        const index = state.orders.findIndex(
          (order) => order.id === action.payload.id
        );
        if (index !== -1) state.orders[index] = action.payload;
      })

      .addCase(ordersThunks.deleteExistingOrder.fulfilled, (state, action) => {
        state.orders = state.orders.filter(
          (order) => order.id !== action.payload
        );
      })

      // OrderDetails reducers
      .addCase(
        orderDetailsThunks.fetchOrderDetails.fulfilled,
        (state, action) => {
          state.orderDetails = action.payload;
        }
      )

      .addCase(
        orderDetailsThunks.fetchOrderDetailById.fulfilled,
        (state, action) => {
          state.currentOrderDetail = action.payload;
        }
      )

      .addCase(
        orderDetailsThunks.fetchOrderWithDetails.fulfilled,
        (state, action) => {
          state.currentOrder = action.payload;

          if (action.payload && action.payload.id) {
            const orderIndex = state.orders.findIndex(
              (order) => order.id === action.payload.id
            );

            if (orderIndex !== -1) {
              state.orders[orderIndex] = {
                ...state.orders[orderIndex],
                ...action.payload,
              };

              console.log(
                `Updated order ${action.payload.id} with product details in orders array`
              );
            }
          }
        }
      )

      .addCase(
        orderDetailsThunks.createOrderDetail.fulfilled,
        (state, action) => {
          state.orderDetails.push(action.payload);
        }
      )

      .addCase(
        orderDetailsThunks.updateOrderDetail.fulfilled,
        (state, action) => {
          const index = state.orderDetails.findIndex(
            (detail) => detail.id === action.payload.id
          );
          if (index !== -1) state.orderDetails[index] = action.payload;
        }
      )

      .addCase(
        orderDetailsThunks.deleteOrderDetail.fulfilled,
        (state, action) => {
          state.orderDetails = state.orderDetails.filter(
            (detail) => detail.id !== action.payload
          );
        }
      );
  },
});

// Selectors
export const selectAllOrders = (state) => state.orders.orders;
export const selectOrderById = (state, orderId) =>
  state.orders.orders.find((order) => order.id === orderId);
export const selectPagination = (state) => state.orders.pagination;
export const selectTotal = (state) => state.orders.pagination.total;
export const selectCurrentPage = (state) => state.orders.pagination.page;
export const selectMaxRecords = (state) => state.orders.pagination.maxRecords;
export const selectTotalPages = (state) => state.orders.pagination.totalPages;

// Existing selectors
export const selectAllOrderDetails = (state) => state.orders.orderDetails;
export const selectOrderDetailById = (state, detailId) =>
  state.orders.orderDetails.find((detail) => detail.id === detailId);
export const selectCurrentOrder = (state) => state.orders.currentOrder;
export const selectCurrentOrderDetail = (state) =>
  state.orders.currentOrderDetail;
export const selectStatus = (state) => state.orders.status;
export const selectError = (state) => state.orders.error;

export const {
  resetState,
  clearCurrentOrder,
  clearCurrentOrderDetail,
  updatePagination,
} = ordersSlice.actions;

export default ordersSlice.reducer;
