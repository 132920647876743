import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ordersApi from '../../api/ordersApi';

export const fetchOrders = createAsyncThunk(
  'orders/fetchOrders',
  async ({ page = 1, maxRecords = 50 }, { rejectWithValue }) => {
    try {
      const response = await ordersApi.getAllOrders(page, maxRecords);
      return {
        orders: response.orders,
        pagination: {
          total: response.total,
          page: response.page,
          maxRecords: response.maxRecords,
          totalPages: response.totalPages,
        },
      };
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrderById = createAsyncThunk(
  'orders/fetchOrderById',
  async (id, { rejectWithValue }) => {
    try {
      return await ordersApi.getOrder(id);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createNewOrder = createAsyncThunk(
  'orders/createOrder',
  async (order, { rejectWithValue }) => {
    try {
      // Ensure both discount fields are included in the payload
      const orderWithDiscountFields = {
        ...order,
        // If the discount fields aren't already set, provide defaults
        discountILS: order.discountILS || '0.00',
        discountPercentage: order.discountPercentage || 0,
      };

      // Remove the old discount field if it exists to prevent confusion
      if ('discount' in orderWithDiscountFields) {
        delete orderWithDiscountFields.discount;
      }

      return await ordersApi.createOrder(orderWithDiscountFields);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateExistingOrder = createAsyncThunk(
  'orders/updateOrder',
  async ({ id, order }, { rejectWithValue }) => {
    try {
      // Ensure both discount fields are included in the payload for updates as well
      const orderWithDiscountFields = {
        ...order,
        discountILS: order.discountILS || '0.00',
        discountPercentage: order.discountPercentage || 0,
      };

      // Remove the old discount field if it exists
      if ('discount' in orderWithDiscountFields) {
        delete orderWithDiscountFields.discount;
      }

      return await ordersApi.updateOrder(id, orderWithDiscountFields);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteExistingOrder = createAsyncThunk(
  'orders/deleteOrder',
  async (id, { rejectWithValue }) => {
    try {
      await ordersApi.deleteOrder(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
