import React from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from '@mui/material';

// Improved date formatting function to handle various formats
const formatDate = (dateString) => {
  if (!dateString) return '';

  try {
    // Check if it's the problematic format like "01T00:00:00.000Z/03/25"
    if (dateString.includes('T') && dateString.includes('/')) {
      // Extract day, month, year
      const parts = dateString.split('/');
      if (parts.length === 3) {
        const day = parts[0].split('T')[0]; // Get the day part before T
        const month = parts[1];
        const year = parts[2];
        return `${day}/${month}/${year}`;
      }
    }

    // Handle ISO date format (YYYY-MM-DD)
    if (dateString.includes('-') && !dateString.includes('T')) {
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year.slice(2)}`;
    }

    // Handle full ISO datetime format (with T and Z)
    if (dateString.includes('T')) {
      // Create a date object and format it
      const date = new Date(dateString);
      if (!isNaN(date.getTime())) {
        // Check if valid date
        return `${String(date.getDate()).padStart(2, '0')}/${String(
          date.getMonth() + 1
        ).padStart(2, '0')}/${String(date.getFullYear()).slice(2)}`;
      }
    }

    // If already in dd/mm/yy format, return as is
    return dateString;
  } catch (error) {
    console.error('Date formatting error:', error);
    return dateString; // Return original if formatting fails
  }
};

// Format time from ISO date or time string - 24h format without seconds
const formatTime = (timeString) => {
  if (!timeString) return '';

  try {
    // Handle time part from full datetime string
    if (timeString.includes('T')) {
      const date = new Date(timeString);
      if (!isNaN(date.getTime())) {
        // Check if valid date
        return date.toLocaleTimeString('he-IL', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false, // Use 24-hour format
          second: undefined, // Exclude seconds
        });
      }
    }

    // If it's already in HH:MM format, return as is
    if (/^\d{1,2}:\d{2}$/.test(timeString)) {
      return timeString;
    }

    // If it contains AM/PM, convert to 24-hour format without seconds
    if (timeString.includes('AM') || timeString.includes('PM')) {
      const timeParts = timeString.split(':');
      let hours = parseInt(timeParts[0], 10);
      const minutes = timeParts[1]?.split(' ')[0];
      const isPM = timeString.includes('PM');

      if (isPM && hours < 12) hours += 12;
      if (!isPM && hours === 12) hours = 0;

      return `${hours.toString().padStart(2, '0')}:${minutes}`;
    }

    // For other formats, try to extract just hours and minutes
    const timeMatch = timeString.match(/(\d{1,2}):(\d{2})/);
    if (timeMatch) {
      return `${timeMatch[1].padStart(2, '0')}:${timeMatch[2]}`;
    }

    return timeString;
  } catch (error) {
    console.error('Time formatting error:', error);
    return timeString; // Return original if formatting fails
  }
};

// Function to handle Hebrew text encoding issues
const fixHebrewText = (text) => {
  try {
    // If text already has Hebrew characters, it's probably fine
    if (/[\u0590-\u05FF]/.test(text)) {
      return text;
    }

    // For garbled Hebrew text, this is a placeholder
    // In a real application, you'd need more sophisticated encoding handling
    return text;
  } catch (error) {
    console.error('Error fixing Hebrew text:', error);
    return text;
  }
};

// Format currency for display
const formatCurrency = (amount) => {
  if (amount === undefined || amount === null) return '₪ 0.00';

  return new Intl.NumberFormat('he-IL', {
    style: 'currency',
    currency: 'ILS',
    minimumFractionDigits: 2,
  }).format(amount);
};

function UnifiedFileTemplate({
  businessName = '',
  businessId = '123456789',
  startDate = '01/01/17',
  endDate = '17/02/17',
  filePath = 'C:\\OPENFRMT\\58002695.17\\1702140',
  regNumber = '',
  generatedDate = '17/02/17',
  generatedTime = '01:40',
  issuedBy = '',
  totalRecords = 0,
  customData = null,
}) {
  // Format dates if they're from input fields (ISO format)
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);
  const formattedGeneratedDate = formatDate(generatedDate);
  const formattedGeneratedTime = formatTime(generatedTime);

  // Fix potential Hebrew encoding issues
  const fixedBusinessName = fixHebrewText(businessName);

  // Use provided data or fallback to static data
  const recordsData = customData || [
    { type: 'A100', description: 'רשומת פתיחה', quantity: 1 },
    { type: 'B110', description: 'חשבון בהנהלת חשבונות', quantity: 668 },
    { type: 'C100', description: 'כותרת מסמך', quantity: 1233 },
    { type: 'D110', description: 'פרטי מסמך', quantity: 26 },
    { type: 'D120', description: 'פרטי קבלה', quantity: 1211 },
    { type: 'Z900', description: 'רשומת סגירה', quantity: 1 },
  ];

  // Find C100 record for summary data
  const c100Record = recordsData.find((record) => record.type === 'C100') || {
    quantity: 0,
    amount: 0,
  };

  // Total quantity
  const totalQuantity = recordsData.reduce(
    (sum, item) =>
      sum + (typeof item.quantity === 'number' ? item.quantity : 0),
    0
  );

  // Document summary data
  const documentSummary = [
    {
      documentNumber: '100',
      documentType: 'הזמנה',
      quantityTotal: c100Record.quantity || 0,
      amountTotal: c100Record.amount || 0,
    },
  ];

  // Compact table styles
  const compactTableStyles = {
    '& th, & td': {
      padding: '4px 8px', // Reduce padding in cells
      fontSize: '0.9rem', // Slightly smaller font
      lineHeight: '1.2', // Reduce line height
    },
    '& thead th': {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
  };

  return (
    <Box
      sx={{
        padding: 4,
        maxWidth: '900px', // Return to original max width for screen display
        margin: '0 auto',
        direction: 'rtl',
        bgcolor: 'white',
        color: 'black',
        border: '1px solid #ddd',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      }}
    >
      {/* Add print-specific styles */}
      <style>
        {`
          @media print {
            body {
              background-color: white !important;
            }
            #printableTemplate {
              background-color: white !important;
              color: black !important;
              border: none !important;
              box-shadow: none !important;
              padding: 0 !important;
              max-width: 100% !important; /* Full width for PDF */
              width: 100% !important;
            }
            #printableTemplate * {
              color: black !important;
            }
            table, th, td {
              border: 1px solid black !important;
              background-color: white !important;
              color: black !important;
              padding: 4px 8px !important;
              font-size: 0.9rem !important;
              line-height: 1.2 !important;
            }
            tr {
              background-color: white !important;
              height: auto !important;
            }
            tr:nth-of-type(odd) {
              background-color: #f5f5f5 !important;
            }
          }
        `}
      </style>
      {/* Header */}
      <Typography
        variant="h5" // Reduced from h4 to h5
        align="center"
        sx={{
          mb: 2, // Reduced margin
          fontWeight: 'bold',
          textDecoration: 'underline',
          color: 'black',
        }}
      >
        הפקת קבצים במבנה אחיד
      </Typography>
      {/* Business Info Section */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
        {' '}
        {/* Reduced margin */}
        <Box sx={{ textAlign: 'right' }}>
          <Typography variant="body2" sx={{ mb: 0.5, color: 'black' }}>
            {' '}
            {/* Smaller text */}
            עבור:
          </Typography>
          <Typography variant="body2" sx={{ mb: 0.5, color: 'black' }}>
            {' '}
            {/* Smaller text */}
            מספר עוסק מורשה: {businessId}
          </Typography>
          <Typography variant="body2" sx={{ mb: 0.5, color: 'black' }}>
            {' '}
            {/* Smaller text */}
            שם בית העסק: {fixedBusinessName}
          </Typography>
        </Box>
      </Box>
      {/* Description Section */}
      <Typography
        variant="subtitle1" // Reduced from h6 to subtitle1
        align="center"
        sx={{ mb: 2, fontWeight: 'bold', color: 'black' }} // Reduced margin
      >
        ** ביצוע ממשק פתוח הסתיים בהצלחה **
      </Typography>
      {/* File Path */}
      <Box sx={{ display: 'flex', mb: 2 }}>
        {' '}
        {/* Reduced margin */}
        <Typography
          variant="body2" // Smaller text
          sx={{ fontWeight: 'bold', ml: 2, color: 'black' }}
        >
          הנתונים נשמרו בנתיב:
        </Typography>
        <Typography variant="body2" sx={{ color: 'black' }}>
          {' '}
          {/* Smaller text */}
          {filePath}
        </Typography>
      </Box>
      {/* Date Range */}
      <Box sx={{ display: 'flex', mb: 2 }}>
        {' '}
        {/* Reduced margin */}
        <Typography
          variant="body2" // Smaller text
          sx={{ fontWeight: 'bold', ml: 2, color: 'black' }}
        >
          טווח תאריכים:
        </Typography>
        <Typography variant="body2" sx={{ ml: 2, color: 'black' }}>
          {' '}
          {/* Smaller text */}
          מתאריך: {formattedStartDate}
        </Typography>
        <Typography variant="body2" sx={{ color: 'black' }}>
          {' '}
          {/* Smaller text */}
          עד תאריך: {formattedEndDate}
        </Typography>
      </Box>
      {/* Records Summary */}
      <Typography
        variant="subtitle1" // Reduced from h6 to subtitle1
        sx={{ mb: 1, fontWeight: 'bold', color: 'black' }} // Reduced margin
      >
        פירוט סוגי הרשומות בקובץ BKMVDATA.TXT:
      </Typography>
      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{
          mb: 2, // Reduced margin
          direction: 'rtl',
          borderRadius: '4px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          bgcolor: 'white',
        }}
      >
        <Table
          aria-label="unified file records"
          size="small" // Use small size for more compact table
          sx={compactTableStyles} // Apply compact styles
        >
          <TableHead>
            <TableRow sx={{ bgcolor: '#f5f5f5' }}>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                סוג רשומה
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                תיאור
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                כמות
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recordsData.map((record, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-of-type(odd)': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
                  bgcolor: 'white',
                  height: 'auto', // Auto height instead of default
                }}
              >
                <TableCell align="right" sx={{ color: 'black' }}>
                  {record.type}
                </TableCell>
                <TableCell align="right" sx={{ color: 'black' }}>
                  {fixHebrewText(record.description)}
                </TableCell>
                <TableCell align="right" sx={{ color: 'black' }}>
                  {record.quantity}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ bgcolor: '#f5f5f5' }}>
              <TableCell
                colSpan={2}
                align="left"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                סה"כ
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                {totalQuantity}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/* Document Summary Section */}
      <Typography
        variant="subtitle1" // Reduced from h6 to subtitle1
        sx={{ mb: 1, fontWeight: 'bold', color: 'black' }} // Reduced margin
      >
        פירוט סוגי המסמכים:
      </Typography>
      {/* Document Summary Table */}
      <TableContainer
        component={Paper}
        sx={{
          mb: 2, // Reduced margin
          direction: 'rtl',
          borderRadius: '4px',
          border: '1px solid rgba(0, 0, 0, 0.12)',
          bgcolor: 'white',
        }}
      >
        <Table
          aria-label="document summary"
          size="small" // Use small size for more compact table
          sx={compactTableStyles} // Apply compact styles
        >
          <TableHead>
            <TableRow sx={{ bgcolor: '#f5f5f5' }}>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                מספר מסמך
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                סוג מסמך
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                סה"כ כמותי
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                סה"כ כספי (בש"ח)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentSummary.map((doc, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:nth-of-type(odd)': { bgcolor: 'rgba(0, 0, 0, 0.04)' },
                  bgcolor: 'white',
                  height: 'auto', // Auto height instead of default
                }}
              >
                <TableCell align="right" sx={{ color: 'black' }}>
                  {doc.documentNumber}
                </TableCell>
                <TableCell align="right" sx={{ color: 'black' }}>
                  {doc.documentType}
                </TableCell>
                <TableCell align="right" sx={{ color: 'black' }}>
                  {doc.quantityTotal}
                </TableCell>
                <TableCell align="right" sx={{ color: 'black' }}>
                  {formatCurrency(doc.amountTotal)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ bgcolor: '#f5f5f5' }}>
              <TableCell
                colSpan={2}
                align="left"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                סה"כ
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                {documentSummary.reduce(
                  (sum, doc) => sum + doc.quantityTotal,
                  0
                )}
              </TableCell>
              <TableCell
                align="right"
                sx={{ fontWeight: 'bold', color: 'black' }}
              >
                {formatCurrency(
                  documentSummary.reduce(
                    (sum, doc) => sum + (doc.amountTotal || 0),
                    0
                  )
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ mb: 2 }} /> {/* Reduced margin */}
      {/* Footer Information */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        {' '}
        {/* Reduced margin */}
        <Typography variant="body2" sx={{ color: 'black' }}>
          {' '}
          {/* Smaller text */}
          הנתונים הופקו באמצעות תוכנת {issuedBy || 'ניהול עסק'}
        </Typography>
        <Typography variant="body2" sx={{ color: 'black' }}>
          {' '}
          {/* Smaller text */} מספר תעודת הרישום: {regNumber || '-'}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2" sx={{ color: 'black' }}>
          {' '}
          {/* Smaller text */}
          בתאריך: {formattedGeneratedDate}
        </Typography>
        <Typography variant="body2" sx={{ color: 'black' }}>
          {' '}
          {/* Smaller text */}
          בשעה: {formattedGeneratedTime}
        </Typography>
      </Box>
      {/* Total Records */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        {' '}
        {/* Reduced margin */}
        <Typography variant="body2" sx={{ color: 'black', fontWeight: 'bold' }}>
          {' '}
          {/* Smaller text */}
          סה״כ רשומות: {totalRecords || totalQuantity}
        </Typography>
      </Box>
    </Box>
  );
}

export default UnifiedFileTemplate;
