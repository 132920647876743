import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import menuReducer from './features/menu/menuSlice';
import cartReducer from './features/cart/cartSlice';
import managerMenuReducer from './features/menu/managerMenuSlice';
import categoryReducer from './features/category/CategorySlice';
import filterReducer from './features/filter/filterSlice';
import authReducer from './features/auth/authSlice';
import workingHoursReducer from './features/workingHours/workingHoursSlice';
import formsReducer from './features/EmployeeForms/formsSlice';
import configurationReducer from './features/configuration/configurationSlice';
import couponsReducer from './features/coupons/couponsSlice';
import ordersReducer from './features/orders/ordersSlice';
import unifiedFileReducer from './features/unifiedFile/unifiedFileSlice';
import filterOptionsReducer from './features/filterOptions/filterOptionSlice';
import notificationReducer from './features/notification/notificationSlice';

const store = configureStore({
  reducer: {
    menu: menuReducer,
    cart: cartReducer,
    managerMenu: managerMenuReducer,
    categories: categoryReducer,
    filters: filterReducer,
    workingHours: workingHoursReducer,
    auth: authReducer,
    forms: formsReducer,
    configuration: configurationReducer,
    coupons: couponsReducer,
    orders: ordersReducer,
    unifiedFile: unifiedFileReducer,
    filterOptions: filterOptionsReducer,
    notification: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [
          'auth/login/fulfilled',
          'auth/logout/fulfilled',
          'auth/check/fulfilled',
        ],
        // Ignore these field paths in all actions
        ignoredPaths: ['auth.user'],
      },
    }),
});

// Enable listeners for RTK-Query (if using)
setupListeners(store.dispatch);

export default store;
