import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Snackbar,
  Tabs,
  Tab,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUnifiedFiles,
  selectUnifiedFileStatus,
  selectUnifiedFileError,
  selectDownloadStatus,
  selectDownloadError,
  selectReportInfo,
  resetDownloadStatus,
} from '../../../features/unifiedFile/unifiedFileSlice';
import { downloadUnifiedFiles } from '../../../features/unifiedFile/unifiedFileThunk';
import UnifiedFileTemplate from './UnifiedFileTemplate';
import RTLDateInput from '../../core/RTLDateInput'; // Import the simpler date input

function GenerateFiles() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'info',
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [currentRequestCompleted, setCurrentRequestCompleted] = useState(false);
  const dispatch = useDispatch();

  // Redux selectors
  const summaryData = useSelector(selectUnifiedFiles) || [];
  const status = useSelector(selectUnifiedFileStatus);
  const error = useSelector(selectUnifiedFileError);
  const downloadStatus = useSelector(selectDownloadStatus);
  const downloadError = useSelector(selectDownloadError);
  const reportInfo = useSelector(selectReportInfo);

  // When starting a new request, reset the completion flag
  const handleUnifiedAction = () => {
    if (!isFormValid()) {
      setNotification({
        open: true,
        message: 'נא לבחור תאריך התחלה ותאריך סיום',
        severity: 'error',
      });
      return;
    }

    // Reset the completion flag when starting a new request
    setCurrentRequestCompleted(false);

    // Show loading state
    dispatch(downloadUnifiedFiles({ startDate, endDate }));
  };

  // When download is successful, mark the current request as completed and switch to the report view
  useEffect(() => {
    if (downloadStatus === 'succeeded') {
      setCurrentRequestCompleted(true);
      setCurrentTab(1);
    }
  }, [downloadStatus]);

  // Reset current request completion on date change
  useEffect(() => {
    setCurrentRequestCompleted(false);
  }, [startDate, endDate]);

  // Handle error notifications
  useEffect(() => {
    if (status === 'failed' && error) {
      setNotification({
        open: true,
        message: `שגיאה בטעינת הנתונים: ${error}`,
        severity: 'error',
      });
    }
  }, [status, error]);

  // Watch for download status changes
  useEffect(() => {
    if (downloadStatus === 'succeeded') {
      setNotification({
        open: true,
        message: 'הקבצים הורדו בהצלחה',
        severity: 'success',
      });
      dispatch(resetDownloadStatus());
    } else if (downloadStatus === 'failed' && downloadError) {
      setNotification({
        open: true,
        message: `שגיאה בהורדת הקבצים: ${downloadError}`,
        severity: 'error',
      });
      dispatch(resetDownloadStatus());
    }
  }, [downloadStatus, downloadError, dispatch]);

  const getTemplateData = () => {
    // If we have actual data from Redux, use it
    if (
      reportInfo &&
      Object.keys(reportInfo).length > 0 &&
      currentRequestCompleted
    ) {
      // Extract the Hebrew text for business name
      let displayBusinessName = reportInfo.businessName;

      // If no business name, use a hard-coded Hebrew name
      if (!displayBusinessName || displayBusinessName.trim() === '') {
        displayBusinessName = 'עגלת קפה אוליבר'; // Oliver Coffee Cart in Hebrew
      }

      // If no records data, use static sample data
      const displayRecords =
        summaryData.length > 0
          ? summaryData
          : [
              { type: 'A100', description: 'רשומת פתיחה', quantity: 1 },
              {
                type: 'C100',
                description: 'כותרת מסמך',
                quantity: 4,
                amount: 1340750.51,
              },
              {
                type: 'B110',
                description: 'חשבון בהנהלת חשבונות',
                quantity: 4,
              },
              { type: 'D110', description: 'פרטי מסמך', quantity: 11 },
              { type: 'M100', description: 'פריטים במלאי', quantity: 10 },
              { type: 'Z900', description: 'רשומת סגירה', quantity: 1 },
            ];

      return {
        businessName: displayBusinessName,
        businessId: reportInfo.businessNumber || '318836426',
        startDate: reportInfo.startDate || startDate || '2025-03-01',
        endDate: reportInfo.endDate || endDate || '2025-03-26',
        filePath: reportInfo.savePath || `C:\\OPENFRMT\\0000009.07\\07171025`,
        regNumber: reportInfo.licenseNumber || '',
        generatedDate: reportInfo.issuedDate || new Date().toISOString(),
        generatedTime: reportInfo.issuedTime || '11:14:45 AM',
        issuedBy: reportInfo.issuedBy || 'Ryzen',
        totalRecords: reportInfo.totalRecords || 31,
        customData: displayRecords,
      };
    }

    // Show empty template or message when no data is available
    return {
      businessName: '',
      businessId: '',
      startDate: '',
      endDate: '',
      filePath: '',
      regNumber: '',
      generatedDate: '',
      generatedTime: '',
      issuedBy: '',
      totalRecords: 0,
      customData: [],
    };
  };

  // Handle PDF download
  const handleDownloadPdf = async () => {
    try {
      const element = document.getElementById('printableTemplate');
      if (element) {
        window.print();
      } else {
        console.error('Element with ID "printableTemplate" not found');
      }
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const handleCloseNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ ...notification, open: false });
  };

  const handleTabChange = (event, newValue) => {
    // Only allow changing to tab 1 if the current request is completed
    if (newValue === 1 && !currentRequestCompleted) {
      return;
    }
    setCurrentTab(newValue);
  };

  const isFormValid = () => {
    return startDate && endDate;
  };

  const isLoading = status === 'loading';
  const isDownloading = downloadStatus === 'loading';

  // Check if we should show content in tab 1
  const showReportContent =
    currentRequestCompleted &&
    currentTab === 1 &&
    reportInfo &&
    Object.keys(reportInfo).length > 0;

  return (
    <>
      {/* CSS for printing */}
      <style>
        {`
          @media print {
            body * {
              visibility: hidden;
            }
            #printableTemplate, #printableTemplate * {
              visibility: visible;
            }
            #printableTemplate {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
            }
            .no-print {
              display: none !important;
            }
          }
        `}
      </style>

      <Box
        sx={{
          padding: 4,
          direction: 'rtl',
          textAlign: 'right',
          justifySelf: 'center',
          width: '100%',
          maxWidth: '800px',
        }}
      >
        <Typography variant="h5" gutterBottom className="no-print">
          יצירת קובץ הזמנות במבנה אחיד
        </Typography>

        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          centered
          sx={{ mb: 3 }}
          className="no-print"
        >
          <Tab label="יצירת קבצים" />
          <Tab label="צפייה בדוח" disabled={!currentRequestCompleted} />
        </Tabs>

        {currentTab === 0 && (
          <Box sx={{ marginBottom: 3, marginTop: 3 }} className="no-print">
            <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
              {/* Use the simple RTL date input component */}
              <RTLDateInput
                label="תאריך התחלה"
                value={startDate}
                onChange={setStartDate}
              />
              <RTLDateInput
                label="תאריך סיום"
                value={endDate}
                onChange={setEndDate}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: 3,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={
                  isLoading || isDownloading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <DownloadIcon />
                  )
                }
                onClick={handleUnifiedAction}
                disabled={!isFormValid() || isLoading || isDownloading}
                sx={{ minWidth: '200px', gap: 1 }}
              >
                {isLoading || isDownloading ? 'מעבד...' : 'יצירת והורדת קבצים'}
              </Button>
            </Box>

            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                שגיאה: {error}
              </Alert>
            )}
          </Box>
        )}

        {currentTab === 1 && (
          <>
            {showReportContent ? (
              <>
                {/* PDF Download button - outside the template */}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 3,
                  }}
                  className="no-print"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<PictureAsPdfIcon />}
                    onClick={handleDownloadPdf}
                    sx={{ minWidth: '200px', gap: 1 }}
                  >
                    הדפס/הורד PDF
                  </Button>
                </Box>

                {/* The actual template - this will be included in PDF */}
                <div id="printableTemplate">
                  <UnifiedFileTemplate {...getTemplateData()} />
                </div>
              </>
            ) : (
              <Box sx={{ textAlign: 'center', py: 4 }}>
                <Typography variant="h6" color="text.secondary">
                  יש ליצור דוח חדש כדי לצפות בנתונים
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        className="no-print"
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default GenerateFiles;
