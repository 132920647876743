import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Tabs,
  Tab,
  CircularProgress,
  Alert,
  Paper,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FilterListIcon from '@mui/icons-material/FilterList';
import GetAppIcon from '@mui/icons-material/GetApp';

// יבוא הקומפוננטות
import SummaryCards from './SummaryCards';
import OverviewTab from './OverviewTab';
import ProductsTab from './ProductsTab';
import HourlyTab from './HourlyTab';
import TrendsTab from './TrendsTab';

// יבוא של האקשנים והסלקטורים הנדרשים
import { fetchOrders } from '../orders/ordersThunk';
import { fetchOrderWithDetails } from '../orders/orderDetailsThunk';
import {
  selectAllOrders,
  selectStatus,
  selectError,
} from '../orders/ordersSlice';
import { selectCategories } from '../category/CategorySlice';
import { processOrdersData } from './DataProcessing';

const CategoryAnalyticsDashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const orders = useSelector(selectAllOrders);
  const categories = useSelector(selectCategories);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);

  const [selectedPeriod, setSelectedPeriod] = useState('week');
  const [selectedView, setSelectedView] = useState('overview');
  const [analyticsData, setAnalyticsData] = useState({
    productData: [],
    dailyData: [],
    hourlyData: [],
    summaryData: {
      totalSales: 0,
      totalOrders: 0,
      avgOrderValue: 0,
      salesChange: 0,
      ordersChange: 0,
      avgValueChange: 0,
    },
  });
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const [loadedOrdersCount, setLoadedOrdersCount] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [categoryTabs, setCategoryTabs] = useState([]);
  const [categoryData, setCategoryData] = useState({});

  // סינון הזמנות לפי קטגוריה
  const filterOrdersByCategory = (orders, categoryId) => {
    if (categoryId === 'all') return orders;

    return orders.filter((order) => {
      // בדיקה שיש פרטי הזמנה
      if (!order.details || !Array.isArray(order.details)) return false;

      // סינון הזמנות שמכילות מוצרים מהקטגוריה הנבחרת
      return order.details.some(
        (detail) =>
          detail.categoryId === categoryId || detail.category?.id === categoryId
      );
    });
  };

  // יצירת טאבים לקטגוריות
  useEffect(() => {
    if (categories && categories.length > 0) {
      // סידור הקטגוריות לפי סדר תצוגה
      const sortedCategories = [...categories].sort(
        (a, b) => a.displayOrder - b.displayOrder
      );

      // יצירת רשימת טאבים
      const tabs = [
        { id: 'all', name: 'כל הקטגוריות' },
        ...sortedCategories.map((cat) => ({ id: cat.id, name: cat.name })),
      ];

      setCategoryTabs(tabs);

      // אם אין קטגוריה נבחרת, בחר את הראשונה
      if (selectedCategory === '' && tabs.length > 0) {
        setSelectedCategory(tabs[0].id);
      }
    }
  }, [categories, selectedCategory]);

  const loadAndExtractProducts = async (categoryId = null) => {
    setIsLoadingDetails(true);
    console.log('Starting manual product extraction...');

    if (orders.length === 0) {
      console.log('No orders to extract products from');
      setIsLoadingDetails(false);
      return;
    }

    let allProducts = [];
    const filteredOrders = categoryId
      ? filterOrdersByCategory(orders, categoryId)
      : orders;
    const numOrdersToCheck = Math.min(10, filteredOrders.length);

    for (let i = 0; i < numOrdersToCheck; i++) {
      const order = filteredOrders[i];

      try {
        // טעינת פרטי הזמנה
        console.log(
          `Loading details for order #${order.id || order.orderNumber}`
        );
        const result = await dispatch(fetchOrderWithDetails(order.id)).unwrap();

        if (result && result.details && Array.isArray(result.details)) {
          console.log(
            `Found ${result.details.length} products in order #${order.id}`
          );

          // סינון לפי קטגוריה אם נדרש
          const relevantDetails =
            categoryId === 'all'
              ? result.details
              : result.details.filter(
                  (detail) =>
                    detail.categoryId === categoryId ||
                    detail.category?.id === categoryId
                );

          // הוספת פרטי המוצרים למערך
          relevantDetails.forEach((detail) => {
            if (detail && detail.menuName) {
              // חיפוש אם המוצר כבר קיים במערך
              const existingProduct = allProducts.find(
                (p) => p.name === detail.menuName
              );

              if (existingProduct) {
                // עדכון נתוני מוצר קיים
                existingProduct.value += Number(detail.quantity || 1);
                existingProduct.amount +=
                  Number(detail.price || 0) * Number(detail.quantity || 1);
                existingProduct.orders += 1;
              } else {
                // הוספת מוצר חדש
                allProducts.push({
                  name: detail.menuName,
                  value: Number(detail.quantity || 1),
                  amount:
                    Number(detail.price || 0) * Number(detail.quantity || 1),
                  orders: 1,
                  color: getRandomColor(theme, allProducts.length),
                  categoryId: detail.categoryId || detail.category?.id,
                });
              }
            }
          });
        }
      } catch (error) {
        console.error(`Error loading details for order #${order.id}:`, error);
      }
    }

    console.log(`Manually extracted ${allProducts.length} unique products`);

    // מיון המוצרים לפי כמות מכירות (מהגבוה לנמוך)
    allProducts.sort((a, b) => b.value - a.value);

    // החזרת נתוני המוצרים
    setIsLoadingDetails(false);
    setIsDataLoaded(true);
    return allProducts;
  };

  // פונקציה לעיבוד הנתונים לפי קטגוריה
  const processDataByCategory = async (categoryId) => {
    setIsLoadingDetails(true);

    try {
      // סינון הזמנות לפי קטגוריה
      const filteredOrders = filterOrdersByCategory(orders, categoryId);
      console.log(
        `Processing ${filteredOrders.length} orders for category ${categoryId}`
      );

      // עיבוד נתונים בסיסי
      const initialData = processOrdersData(filteredOrders, theme);

      // טעינת נתוני מוצרים
      const productData = await loadAndExtractProducts(categoryId);

      // עדכון נתוני המוצרים בתוצאה
      const result = {
        ...initialData,
        productData: productData || initialData.productData,
      };

      // שמירת הנתונים בקאש לפי קטגוריה
      setCategoryData((prev) => ({
        ...prev,
        [categoryId]: result,
      }));

      setIsLoadingDetails(false);
      return result;
    } catch (error) {
      console.error('Error processing data by category:', error);
      setIsLoadingDetails(false);
      return null;
    }
  };

  // בעת שינוי הקטגוריה הנבחרת
  useEffect(() => {
    if (selectedCategory && orders.length > 0) {
      // אם יש כבר נתונים לקטגוריה זו בקאש, השתמש בהם
      if (categoryData[selectedCategory]) {
        setAnalyticsData(categoryData[selectedCategory]);
      } else {
        // אחרת, עבד את הנתונים
        processDataByCategory(selectedCategory).then((data) => {
          if (data) {
            setAnalyticsData(data);
          }
        });
      }
    }
  }, [selectedCategory, orders]);

  // פונקציה עזר לקבלת צבע רנדומלי
  const getRandomColor = (theme, index) => {
    const colors = [
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.secondary.main,
      theme.palette.secondary.light,
      theme.palette.success.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ];

    return colors[index % colors.length];
  };

  // טעינת נתונים ראשונית
  useEffect(() => {
    // טעינת הזמנות אם אין עדיין נתונים
    if (status !== 'loading' && status !== 'succeeded' && orders.length === 0) {
      console.log('Fetching orders data...');
      dispatch(fetchOrders({ page: 1, maxRecords: 50 }));
    }
    // אם יש הזמנות אבל עדיין לא עיבדנו את הדאטה
    else if (!isDataLoaded && orders.length > 0) {
      console.log(`Found ${orders.length} orders to process`);

      // עיבוד נתונים בסיסיים (ללא פרטי מוצרים)
      const initialData = processOrdersData(orders, theme);
      setAnalyticsData(initialData);

      // בדיקה כמה הזמנות יש להן כבר פרטי מוצרים
      const ordersWithDetails = orders.filter(
        (order) =>
          order.details &&
          Array.isArray(order.details) &&
          order.details.length > 0
      );
      setLoadedOrdersCount(ordersWithDetails.length);

      console.log(`${ordersWithDetails.length} orders already have details`);

      // אם אין מספיק מוצרים, טען אותם אוטומטית
      if (initialData.productData.length === 0) {
        loadAndExtractProducts().then((productData) => {
          if (productData && productData.length > 0) {
            setAnalyticsData((prev) => ({
              ...prev,
              productData: productData,
            }));

            // שמירה בקאש של הקטגוריה "הכל"
            setCategoryData((prev) => ({
              ...prev,
              all: {
                ...initialData,
                productData: productData,
              },
            }));
          }
        });
      } else {
        // שמירה בקאש של הקטגוריה "הכל"
        setCategoryData((prev) => ({
          ...prev,
          all: initialData,
        }));
        setIsDataLoaded(true);
      }
    }
  }, [dispatch, orders, isDataLoaded, theme, status]);

  // כשמשתנה הפילטר של התקופה
  useEffect(() => {
    if (isDataLoaded && orders.length > 0 && selectedCategory) {
      // עיבוד הנתונים מחדש לפי הפילטר שנבחר
      console.log(`Filtering data for period: ${selectedPeriod}`);

      // בהמשך ניתן להוסיף פה היגיון לסינון לפי תקופה
      // למשל: לסנן רק הזמנות מהשבוע האחרון אם selectedPeriod === 'week'

      // עבד את הנתונים מחדש לפי הקטגוריה הנבחרת
      processDataByCategory(selectedCategory).then((data) => {
        if (data) {
          setAnalyticsData(data);
        }
      });
    }
  }, [selectedPeriod]);

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handleViewChange = (event, newValue) => {
    setSelectedView(newValue);
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  // הצגת מצב טעינה ראשוני
  if (status === 'loading' && !isDataLoaded) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
        }}
      >
        <CircularProgress />
        <Typography variant="h6" sx={{ mr: 2 }}>
          טוען נתונים...
        </Typography>
      </Box>
    );
  }

  // הצגת שגיאה אם יש
  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">שגיאה בטעינת הנתונים: {error}</Alert>
      </Box>
    );
  }

  // מספר המוצרים שזוהו
  const totalProductsCount = analyticsData.productData.length;

  // שם הקטגוריה הנבחרת
  const selectedCategoryName =
    categoryTabs.find((tab) => tab.id === selectedCategory)?.name ||
    'כל הקטגוריות';

  return (
    <Box sx={{ p: 3, direction: 'rtl' }}>
      {/* כותרת וסינון */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
          flexWrap: 'wrap',
        }}
      >
        <Typography variant="h4" component="h1" fontWeight="bold">
          פילוח נתונים
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, mt: { xs: 2, sm: 0 } }}>
          <FormControl size="small" variant="outlined" sx={{ minWidth: 120 }}>
            <Select
              value={selectedPeriod}
              onChange={handlePeriodChange}
              startAdornment={
                <CalendarTodayIcon fontSize="small" sx={{ mr: 1 }} />
              }
            >
              <MenuItem value="today">היום</MenuItem>
              <MenuItem value="week">שבוע אחרון</MenuItem>
              <MenuItem value="month">חודש אחרון</MenuItem>
              <MenuItem value="quarter">רבעון</MenuItem>
              <MenuItem value="year">שנה</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            startIcon={<FilterListIcon />}
            size="small"
          >
            סינון
          </Button>
          <Button variant="outlined" startIcon={<GetAppIcon />} size="small">
            ייצוא
          </Button>
        </Box>
      </Box>

      {/* מציג מידע על כמות ההזמנות שנטענו */}
      {orders.length > 0 && (
        <Alert severity="info" sx={{ mb: 2 }}>
          {orders.length} הזמנות נטענו לניתוח.
          {loadedOrdersCount > 0 &&
            ` (${loadedOrdersCount} הזמנות עם פרטי מוצרים)`}
          {totalProductsCount > 0 && ` ${totalProductsCount} סוגי מוצרים זוהו.`}
          {isLoadingDetails && ' (טוען פרטי מוצרים...)'}
        </Alert>
      )}

      {/* טאבים של קטגוריות */}
      <Paper sx={{ mb: 3 }}>
        <Tabs
          value={selectedCategory}
          onChange={handleCategoryChange}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          {categoryTabs.map((tab) => (
            <Tab key={tab.id} label={tab.name} value={tab.id} />
          ))}
        </Tabs>
      </Paper>

      {isLoadingDetails ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>
            טוען נתוני {selectedCategoryName}...
          </Typography>
        </Box>
      ) : (
        <>
          {/* כרטיסי סיכום */}
          <SummaryCards data={analyticsData.summaryData} />

          {/* כפתורי ניווט בין תצוגות */}
          <Tabs
            value={selectedView}
            onChange={handleViewChange}
            sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
          >
            <Tab label="סקירה כללית" value="overview" />
            <Tab label="מוצרים" value="products" />
            <Tab label="שעות פעילות" value="hourly" />
            <Tab label="מגמות" value="trends" />
          </Tabs>

          {/* תצוגת תוכן לפי הטאב שנבחר */}
          {selectedView === 'overview' && <OverviewTab data={analyticsData} />}
          {selectedView === 'products' && <ProductsTab data={analyticsData} />}
          {selectedView === 'hourly' && <HourlyTab data={analyticsData} />}
          {selectedView === 'trends' && <TrendsTab />}
        </>
      )}
    </Box>
  );
};

export default CategoryAnalyticsDashboard;
