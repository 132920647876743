import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import AnalyticDashboard from '../features/analytics/AnalyticDashboard';
import DashboardNavbar from '../components/dashboardComponents/utils/DashboardNavbar.js';

/**
 * דף סטטיסטיקות המציג את דשבורד הניתוח
 */
const StatisticsPage = () => {
  return (
    <Box>
      <DashboardNavbar />
      <Paper sx={{ p: 2, mb: 2, textAlign: 'right' }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{ mb: 2, fontWeight: 'bold' }}
        >
          סטטיסטיקות וניתוח נתונים
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          .צפה במידע על ההזמנות, מכירות לפי מוצר, ומגמות עסקיות על פני זמן
        </Typography>
      </Paper>

      <AnalyticDashboard />
    </Box>
  );
};

export default StatisticsPage;
