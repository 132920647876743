import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/orders';
const local_URL = 'http://localhost:3001/api/orders';
/* eslint-enable no-unused-vars */

const BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Fetch all orders
export const getAllOrders = async (page, maxRecords) => {
  const response = await api.get(BASE_URL, {
    params: {
      page,
      maxRecords,
    },
  });
  return response.data;
};
// Fetch a single order by ID
export const getOrder = async (id) => {
  const response = await api.get(BASE_URL + `/${id}`);
  return response.data;
};

// Create a new order
export const createOrder = async (order) => {
  const response = await api.post(BASE_URL, order);
  return response.data;
};

// Update an order
export const updateOrder = async (id, order) => {
  const response = await api.put(BASE_URL + `/${id}`, order);
  return response.data;
};

// Delete an order
export const deleteOrder = async (id) => {
  const response = await api.delete(BASE_URL + `/${id}`);
  return response.data;
};

// Fetch all orderDetails
export const getOrderDetails = async () => {
  const response = await api.get(BASE_URL + '/details');
  return response.data;
};

// Fetch a single orderDetail by ID
export const getOrderDetail = async (id) => {
  const response = await api.get(BASE_URL + `/details/${id}`);
  return response.data;
};

// Create a new orderDetail
export const createOrderDetail = async (orderDetail) => {
  const response = await api.post(BASE_URL + '/details', orderDetail);
  return response.data;
};

// Update an orderDetail
export const updateOrderDetail = async (id, orderDetail) => {
  const response = await api.put(BASE_URL + `/details/${id}`, orderDetail);
  return response.data;
};

// Delete an orderDetail
export const deleteOrderDetail = async (id) => {
  const response = await api.delete(BASE_URL + `/details/${id}`);
  return response.data;
};

// Fetch all orderDetails by order ID
export const getOrderWithDetails = async (id) => {
  const response = await api.get(BASE_URL + `/${id}/details`);
  return response.data;
};
