import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const SummaryCards = ({ data }) => {
  const theme = useTheme();

  // פונקציה להתאמת צבעי סטטוס
  const getChangeColor = (value) => {
    return value >= 0 ? theme.palette.success.main : theme.palette.error.main;
  };

  // פונקציה לתצוגת אייקון שינוי
  const renderChangeIcon = (value) => {
    return value >= 0 ? 
      <ArrowUpwardIcon fontSize="small" /> : 
      <ArrowDownwardIcon fontSize="small" />;
  };

  return (
    <Grid container spacing={3} sx={{ mb: 3 }}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography color="text.secondary" variant="subtitle2">
                סך מכירות
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: getChangeColor(data.salesChange) 
              }}>
                {renderChangeIcon(data.salesChange)}
                <Typography variant="caption">
                  {Math.abs(data.salesChange)}%
                </Typography>
              </Box>
            </Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              ₪{data.totalSales.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              לעומת תקופה קודמת
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography color="text.secondary" variant="subtitle2">
                מספר הזמנות
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: getChangeColor(data.ordersChange) 
              }}>
                {renderChangeIcon(data.ordersChange)}
                <Typography variant="caption">
                  {Math.abs(data.ordersChange)}%
                </Typography>
              </Box>
            </Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              {data.totalOrders.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              לעומת תקופה קודמת
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography color="text.secondary" variant="subtitle2">
                ממוצע להזמנה
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: getChangeColor(data.avgValueChange) 
              }}>
                {renderChangeIcon(data.avgValueChange)}
                <Typography variant="caption">
                  {Math.abs(data.avgValueChange)}%
                </Typography>
              </Box>
            </Box>
            <Typography variant="h4" component="div" fontWeight="bold">
              ₪{data.avgOrderValue.toLocaleString()}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              לעומת תקופה קודמת
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default SummaryCards;