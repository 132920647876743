// unifiedFileSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchUnifiedFiles, downloadUnifiedFiles } from './unifiedFileThunk';

export const unifiedFileSlice = createSlice({
  name: 'unifiedFile',
  initialState: {
    files: [],
    reportInfo: {
      businessName: '',
      businessNumber: '',
      savePath: '',
      startDate: '',
      endDate: '',
      issuedBy: '',
      issuedDate: '',
      issuedTime: '',
      licenseNumber: '',
      totalRecords: 0,
    },
    status: 'idle',
    error: null,
    downloadStatus: 'idle',
    downloadError: null,
  },
  reducers: {
    resetDownloadStatus: (state) => {
      state.downloadStatus = 'idle';
      state.downloadError = null;
    },
    resetStatus: (state) => {
      state.status = 'idle';
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchUnifiedFiles
      .addCase(fetchUnifiedFiles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUnifiedFiles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.files = Array.isArray(action.payload) ? action.payload : [];
        state.error = null;
      })
      .addCase(fetchUnifiedFiles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

      // Handle downloadUnifiedFiles
      .addCase(downloadUnifiedFiles.pending, (state) => {
        state.downloadStatus = 'loading';
      })
      .addCase(downloadUnifiedFiles.fulfilled, (state, action) => {
        state.downloadStatus = 'succeeded';
        state.downloadError = null;

        // Update files with the response data if available
        if (action.payload.files && action.payload.files.length > 0) {
          state.files = action.payload.files;
        }

        // Update report information
        if (action.payload.reportInfo) {
          state.reportInfo = {
            ...state.reportInfo,
            ...action.payload.reportInfo,
          };
        }
      })
      .addCase(downloadUnifiedFiles.rejected, (state, action) => {
        state.downloadStatus = 'failed';
        state.downloadError = action.error.message;
      });
  },
});

// Export actions
export const { resetDownloadStatus, resetStatus } = unifiedFileSlice.actions;

// Selectors
export const selectUnifiedFiles = (state) => state.unifiedFile.files;
export const selectUnifiedFileStatus = (state) => state.unifiedFile.status;
export const selectUnifiedFileError = (state) => state.unifiedFile.error;
export const selectDownloadStatus = (state) => state.unifiedFile.downloadStatus;
export const selectDownloadError = (state) => state.unifiedFile.downloadError;
export const selectReportInfo = (state) => state.unifiedFile.reportInfo;

export default unifiedFileSlice.reducer;
