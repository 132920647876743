import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Button,
  alpha
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const TrendsTab = () => {
  const theme = useTheme();

  // נתוני מגמות חודשיות
  const monthlyTrendsData = [
    { month: 'ינואר', sales: 18500, orders: 1230 },
    { month: 'פברואר', sales: 19200, orders: 1280 },
    { month: 'מרץ', sales: 21500, orders: 1420 },
    { month: 'אפריל', sales: 23800, orders: 1560 },
    { month: 'מאי', sales: 26700, orders: 1740 },
    { month: 'יוני', sales: 32450, orders: 2120 },
  ];

  // נתוני צמיחה חודשית
  const growthData = [
    { label: 'הכנסות', value: 18.2 },
    { label: 'הזמנות', value: 11.5 },
    { label: 'ערך הזמנה ממוצע', value: 4.8 }
  ];

  // תובנות
  const insightsData = [
    { 
      title: 'מוצר צומח: לאטה', 
      description: 'עלייה של 28% במכירות לאטה בחודש האחרון. שקול להרחיב את מגוון האפשרויות.',
      color: theme.palette.primary.main
    },
    { 
      title: 'שעת שיא חדשה', 
      description: 'זוהה גידול של 15% במכירות בשעה 14:00. שקול תגבור כוח אדם בשעה זו.',
      color: theme.palette.success.main
    },
    { 
      title: 'יום פופולרי: יום ד\'', 
      description: 'יום רביעי מציג את המכירות הגבוהות ביותר - 22% מעל הממוצע היומי.',
      color: theme.palette.secondary.main
    }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="מגמות חודשיות" />
          <CardContent>
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={monthlyTrendsData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis yAxisId="left" orientation="left" stroke={theme.palette.primary.main} />
                  <YAxis yAxisId="right" orientation="right" stroke={theme.palette.success.main} />
                  <Tooltip formatter={(value) => [value.toLocaleString()]} />
                  <Legend />
                  <Line 
                    yAxisId="left" 
                    type="monotone" 
                    dataKey="sales" 
                    name="מכירות (₪)" 
                    stroke={theme.palette.primary.main} 
                    activeDot={{ r: 8 }} 
                  />
                  <Line 
                    yAxisId="right" 
                    type="monotone" 
                    dataKey="orders" 
                    name="הזמנות" 
                    stroke={theme.palette.success.main} 
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="צמיחה חודשית" />
          <CardContent>
            <Box sx={{ space: 'y-4' }}>
              {growthData.map((item, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                    <Typography variant="body2">{item.label}</Typography>
                    <Typography variant="body2" fontWeight="medium" color={theme.palette.success.main}>
                      +{item.value}%
                    </Typography>
                  </Box>
                  <Box sx={{ width: '100%', bgcolor: alpha(theme.palette.success.main, 0.2), borderRadius: 1, height: 8 }}>
                    <Box 
                      sx={{ 
                        width: `${item.value}%`, 
                        bgcolor: theme.palette.success.main, 
                        borderRadius: 1, 
                        height: '100%' 
                      }}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
            <Box sx={{ mt: 4, p: 3, bgcolor: alpha(theme.palette.success.main, 0.1), borderRadius: 2 }}>
              <Typography variant="subtitle2" sx={{ color: theme.palette.success.dark, mb: 1 }}>
                חדשות טובות!
              </Typography>
              <Typography variant="body2" sx={{ color: theme.palette.success.dark }}>
                המכירות שלך במגמת עלייה ב-3 החודשים האחרונים. המשך לעקוב אחרי המוצרים הפופולריים ביותר.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="תובנות" />
          <CardContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              {insightsData.map((insight, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Box 
                    sx={{ 
                      bgcolor: alpha(insight.color, 0.1), 
                      color: insight.color, 
                      p: 1, 
                      borderRadius: '50%',
                      mr: 2, 
                      mt: 0.5 
                    }}
                  >
                    <TrendingUpIcon fontSize="small" />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2">{insight.title}</Typography>
                    <Typography variant="body2" color="text.secondary">{insight.description}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
            <Button 
              sx={{ 
                mt: 3, 
                width: '100%', 
                color: theme.palette.primary.main, 
                bgcolor: alpha(theme.palette.primary.main, 0.1),
                '&:hover': {
                  bgcolor: alpha(theme.palette.primary.main, 0.2),
                }
              }} 
              variant="text"
            >
              צפה בכל התובנות
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TrendsTab;