import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  alpha
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const HourlyTab = ({ data }) => {
  const theme = useTheme();
  const { hourlyData } = data;

  // תצוגת שעות שיא - נתונים לדוגמה
  const peakHoursData = [
    { hour: '13:00', sales: 410, percentage: 100 },
    { hour: '14:00', sales: 380, percentage: 92 },
    { hour: '12:00', sales: 350, percentage: 85 }
  ];

  // נתוני חלוקת זמנים
  const timeDistributionData = [
    { period: 'בוקר (07:00-11:00)', orders: 69, sales: 1240, percentage: 28 },
    { period: 'צהריים (11:00-14:00)', orders: 86, sales: 1140, percentage: 39 },
    { period: 'אחה"צ (14:00-18:00)', orders: 51, sales: 720, percentage: 22 }
  ];

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="מכירות לפי שעות" />
          <CardContent>
            <Box sx={{ height: 400 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={hourlyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="hour" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar 
                    dataKey="sales" 
                    name="מכירות (₪)" 
                    fill={theme.palette.primary.main} 
                  />
                  <Bar 
                    dataKey="orders" 
                    name="הזמנות" 
                    fill={theme.palette.success.main} 
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="שעות שיא" />
          <CardContent>
            {peakHoursData.map((item, index) => (
              <Box key={index} sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="body2">{item.hour} - {index === 0 ? 'שעת שיא מכירות' : `שעת שיא ${index === 1 ? 'שניה' : 'שלישית'}`}</Typography>
                  <Typography variant="body2" fontWeight="medium">₪{item.sales}</Typography>
                </Box>
                <Box sx={{ width: '100%', bgcolor: alpha(theme.palette.primary.main, 0.2), borderRadius: 1, height: 8 }}>
                  <Box 
                    sx={{ 
                      width: `${item.percentage}%`, 
                      bgcolor: theme.palette.primary.main, 
                      borderRadius: 1, 
                      height: '100%' 
                    }}
                  />
                </Box>
              </Box>
            ))}
            
            <Box sx={{ p: 2, bgcolor: alpha(theme.palette.info.main, 0.1), borderRadius: 1 }}>
              <Typography variant="body2" color="text.secondary">
                שעות השיא הן בין 12:00 ל-14:00 - מומלץ לוודא תגבור כוח אדם בשעות אלו
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader title="חלוקת הזמנות" />
          <CardContent>
            <Box sx={{ textAlign: 'center' }}>
              {timeDistributionData.map((item, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                  <Typography variant="caption" color="text.secondary">{item.period}</Typography>
                  <Typography variant="h5" fontWeight="bold">{item.orders} הזמנות</Typography>
                  <Typography variant="body2">₪{item.sales.toLocaleString()} ({item.percentage}% מסך ההזמנות)</Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HourlyTab;