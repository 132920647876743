/**
 * עיבוד נתונים לתצוגה בדשבורד האנליטי עם תמיכה בקטגוריות
 */

// פונקציה ראשית לעיבוד נתוני הזמנות
export const processOrdersData = (orders, theme, categoryId = null) => {
  console.log(
    `Processing ${orders.length} orders for analytics${
      categoryId ? ` (category: ${categoryId})` : ''
    }`
  );

  if (!orders || orders.length === 0) {
    return getEmptyData();
  }

  // אתחול מערכי נתונים
  const newDailyData = getDailyDataTemplate();
  const newHourlyData = getHourlyDataTemplate();
  const productDataMap = new Map();

  // אתחול נתוני סיכום
  let totalSales = 0;
  let totalOrders = 0; // נחשב רק הזמנות רלוונטיות לקטגוריה הנבחרת
  let processedOrdersWithDetails = 0;
  let totalProducts = 0;
  let totalOrdersCount = orders.length; // סך כל ההזמנות לפני סינון

  // עיבוד הזמנות
  orders.forEach((order) => {
    // בדיקה אם יש פרטי מוצרים בהזמנה
    const hasDetails =
      order.details && Array.isArray(order.details) && order.details.length > 0;

    // סינון לפי קטגוריה אם נדרש
    let filteredDetails = [];
    let isRelevantOrder = true;

    if (hasDetails) {
      // אם נבחרה קטגוריה ספציפית, סנן רק מוצרים מאותה קטגוריה
      if (categoryId && categoryId !== 'all') {
        filteredDetails = order.details.filter(
          (detail) =>
            detail.categoryId === categoryId ||
            (detail.category && detail.category.id === categoryId)
        );

        // אם אין מוצרים מהקטגוריה הנבחרת, דלג על ההזמנה הזו
        if (filteredDetails.length === 0) {
          isRelevantOrder = false;
        }
      } else {
        filteredDetails = [...order.details];
      }
    }

    // אם ההזמנה אינה רלוונטית לקטגוריה הנבחרת, דלג עליה
    if (!isRelevantOrder) {
      return;
    }

    // הזמנה רלוונטית - עדכן את המונה
    totalOrders++;

    // חישוב סך המכירות
    let orderTotal = Number(order.totalPrice || 0);

    // אם יש סינון לפי קטגוריה ויש פרטי הזמנה, חשב סכום מחדש רק עבור המוצרים הרלוונטיים
    if (categoryId && categoryId !== 'all' && hasDetails) {
      orderTotal = filteredDetails.reduce((sum, detail) => {
        const price = Number(detail.price || 0);
        const quantity = Number(detail.quantity || 1);
        return sum + price * quantity;
      }, 0);
    }

    totalSales += orderTotal;

    // סידור לפי יום בשבוע
    try {
      const orderDate = new Date(order.createdAt);
      if (isValidDate(orderDate)) {
        const dayOfWeek = orderDate.getDay(); // 0=ראשון, 1=שני וכו'

        // עדכון נתוני מכירות יומיים
        if (newDailyData[dayOfWeek]) {
          newDailyData[dayOfWeek].sales += orderTotal;
          newDailyData[dayOfWeek].orders += 1;
        }

        // סידור לפי שעה
        const hour = orderDate.getHours();
        const hourIndex = Math.min(Math.max(0, hour - 7), 10); // תואם למערך שעות בין 7 ל-17

        if (hourIndex >= 0 && hourIndex < newHourlyData.length) {
          newHourlyData[hourIndex].sales += orderTotal;
          newHourlyData[hourIndex].orders += 1;
        }
      }
    } catch (error) {
      console.error('Error processing date for order:', error);
    }

    // עיבוד פרטי מוצרים
    if (hasDetails) {
      processedOrdersWithDetails++;
      totalProducts += filteredDetails.length;

      // עיבוד פרטי המוצרים בהזמנה
      filteredDetails.forEach((detail) => {
        if (!detail) return;

        // חילוץ נתוני מוצר לפי המבנה שאנחנו רואים במערכת
        const menuName = detail.menuName || 'לא ידוע';
        const menuPrice = Number(detail.price || 0);
        const menuQuantity = Number(detail.quantity || 1);
        const detailCategoryId =
          detail.categoryId || (detail.category && detail.category.id) || null;

        // חישוב מחיר כולל למוצר
        const totalItemPrice = menuPrice * menuQuantity;

        // הוספת המוצר למפה או עדכון אם כבר קיים
        if (productDataMap.has(menuName)) {
          const product = productDataMap.get(menuName);
          product.value += menuQuantity;
          product.amount += totalItemPrice;
          product.orders += 1;
        } else {
          productDataMap.set(menuName, {
            name: menuName,
            value: menuQuantity,
            amount: totalItemPrice,
            orders: 1,
            color: getRandomColor(theme, productDataMap.size),
            categoryId: detailCategoryId,
          });
        }
      });
    }
  });

  console.log(
    `Processed ${processedOrdersWithDetails} orders with product details (${totalOrders} relevant to category)`
  );
  console.log(
    `Found ${productDataMap.size} unique products, ${totalProducts} total product items`
  );

  // המרת מפת המוצרים למערך
  const productData = Array.from(productDataMap.values());

  // מיון המוצרים לפי כמות מכירות (מהגבוה לנמוך)
  productData.sort((a, b) => b.value - a.value);

  // עדכון נתוני סיכום
  const summaryData = {
    totalSales,
    totalOrders,
    avgOrderValue: totalOrders > 0 ? Math.round(totalSales / totalOrders) : 0,
    totalProducts: productDataMap.size,
    salesChange: calculateGrowth(10), // ערכים לדוגמה - יש להחליף עם נתונים אמיתיים
    ordersChange: calculateGrowth(5), // ערכים לדוגמה - יש להחליף עם נתונים אמיתיים
    avgValueChange: calculateGrowth(2), // ערכים לדוגמה - יש להחליף עם נתונים אמיתיים
    ordersTotalCount: totalOrdersCount, // מספר הזמנות כולל לפני סינון
  };

  return {
    productData,
    dailyData: newDailyData,
    hourlyData: newHourlyData,
    summaryData,
  };
};

// פונקציה ליצירת ערכי צמיחה - בהמשך תוחלף בחישוב אמיתי
function calculateGrowth(seed) {
  // מייצר ערך אקראי בין -15 ל-20 עם הטיה קלה לכיוון חיובי
  return Math.round(Math.random() * 35 - 15 + seed);
}

// פונקציה לבדיקה שהתאריך תקין
export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date);
};

// פונקציה לקבלת צבע רנדומלי מסכמת הצבעים
export const getRandomColor = (theme, index) => {
  const colors = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.secondary.light,
    theme.palette.success.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  return colors[index % colors.length];
};

// פונקציה לאתחול תבנית נתונים יומית
export const getDailyDataTemplate = () => {
  return [
    { name: 'א', sales: 0, orders: 0 },
    { name: 'ב', sales: 0, orders: 0 },
    { name: 'ג', sales: 0, orders: 0 },
    { name: 'ד', sales: 0, orders: 0 },
    { name: 'ה', sales: 0, orders: 0 },
    { name: 'ו', sales: 0, orders: 0 },
    { name: 'ש', sales: 0, orders: 0 },
  ];
};

// פונקציה לאתחול תבנית נתונים לפי שעה
export const getHourlyDataTemplate = () => {
  return [
    { hour: '07:00', sales: 0, orders: 0 },
    { hour: '08:00', sales: 0, orders: 0 },
    { hour: '09:00', sales: 0, orders: 0 },
    { hour: '10:00', sales: 0, orders: 0 },
    { hour: '11:00', sales: 0, orders: 0 },
    { hour: '12:00', sales: 0, orders: 0 },
    { hour: '13:00', sales: 0, orders: 0 },
    { hour: '14:00', sales: 0, orders: 0 },
    { hour: '15:00', sales: 0, orders: 0 },
    { hour: '16:00', sales: 0, orders: 0 },
    { hour: '17:00', sales: 0, orders: 0 },
  ];
};

// נתונים ריקים כשאין הזמנות
export const getEmptyData = () => {
  return {
    productData: [],
    dailyData: getDailyDataTemplate(),
    hourlyData: getHourlyDataTemplate(),
    summaryData: {
      totalSales: 0,
      totalOrders: 0,
      avgOrderValue: 0,
      totalProducts: 0,
      salesChange: 0,
      ordersChange: 0,
      avgValueChange: 0,
    },
  };
};

// סינון מוצרים לפי קטגוריה
export const filterProductsByCategory = (products, categoryId) => {
  if (!categoryId || categoryId === 'all') return products;

  return products.filter((product) => product.categoryId === categoryId);
};

// סינון הזמנות לפי קטגוריה
export const filterOrdersByCategory = (orders, categoryId) => {
  if (!categoryId || categoryId === 'all') return orders;

  return orders.filter((order) => {
    // בדיקה שיש פרטי הזמנה
    if (!order.details || !Array.isArray(order.details)) return false;

    // סינון הזמנות שמכילות מוצרים מהקטגוריה הנבחרת
    return order.details.some(
      (detail) =>
        detail.categoryId === categoryId || detail.category?.id === categoryId
    );
  });
};
