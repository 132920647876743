import { io } from 'socket.io-client';
import store from '../store';
import { fetchConfiguration } from '../features/configuration/configurationThunk';
import { fetchMenuItems } from '../features/menu/menuThunks';
import { fetchManagerMenuItems } from '../features/menu/managerMenuThunks';
import { fetchCategories } from '../features/category/CategoryThunk';
import { fetchWorkingHours } from '../features/workingHours/workingHoursThunk';
import { fetchCoupons } from '../features/coupons/couponsThunk';

class SocketService {
  constructor() {
    this.socket = null;
    this.connected = false;
    this.reconnecting = false;
  }

  initialize() {
    /* eslint-disable no-unused-vars */
    const render_URL = 'https://api.olivercoffeecart.online'; // use for the remote backend
    const local_URL = 'http://localhost:3001'; // use for local backend
    /* eslint-enable no-unused-vars */
    const BASE_URL =
      process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

    console.log('Initializing socket connection to:', BASE_URL);

    this.socket = io(BASE_URL, {
      withCredentials: true, // Important: sends cookies with the connection
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 10000,
      // Force WebSocket first, fallback to polling if necessary
      transports: ['websocket', 'polling'],
    });

    // Connection event handlers with more verbose logging
    this.socket.on('connect', () => {
      console.log('Socket CONNECTED, socket ID:', this.socket.id);
      this.handleConnect();
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Socket DISCONNECTED, reason:', reason);
      this.handleDisconnect();
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket CONNECTION ERROR:', error);
      this.handleError(error);
    });

    // Log all received events for debugging
    this.socket.onAny((event, ...args) => {
      console.log('Socket EVENT received:', event, args);
    });

    // Test event to confirm connection is working
    this.socket.on('connection:test', (data) => {
      console.log('Connection test received:', data);
    });

    // Register event listeners for store events
    this.socket.on(
      'configuration:updated',
      this.handleConfigurationUpdate.bind(this)
    );
    this.socket.on('menu:updated', this.handleMenuUpdate.bind(this));
    this.socket.on(
      'workingHours:updated',
      this.handleWorkingHoursUpdate.bind(this)
    );
    this.socket.on('prices:updated', this.handlePricesUpdate.bind(this));
    this.socket.on(
      'categories:updated',
      this.handleCategoriesUpdate.bind(this)
    );
    this.socket.on(
      'coupons:updated',
        this.handleCategoriesUpdate.bind(this)
    );
    this.socket.on(
        'general:updated',
        this.handleCategoriesUpdate.bind(this)
    );
    this.socket.on(
      'cache:invalidated',
      this.handleCacheInvalidation.bind(this)
    );

    console.log('Socket event listeners registered');
  }

  handleConnect() {
    console.log('Connected to socket server');
    this.connected = true;

    // If we were previously disconnected and reconnected, refresh data
    if (this.reconnecting) {
      this.refreshAllData();
      this.reconnecting = false;
    }
  }

  handleDisconnect() {
    console.log('Disconnected from socket server');
    this.connected = false;
    this.reconnecting = true;
  }

  handleError(error) {
    console.error('Socket connection error:', error);
  }

  // Refresh all data and then show notification
  refreshAllDataWithNotification() {
    console.log('Refreshing all application data due to socket event');

    // Clear localStorage for cache invalidation
    localStorage.removeItem('appCache');

    // Also clear cart data to prevent inconsistencies
    localStorage.removeItem('cartItems');
    localStorage.removeItem('cartCoupon');
    localStorage.removeItem('cartDiscountILS');
    localStorage.removeItem('cartDiscountPercentage');
    localStorage.removeItem('cartDiscountedPrice');

    // Clear the cart
    store.dispatch({ type: 'cart/clearCart' });

    // Dispatch actions to reload all data
    Promise.all([
      store.dispatch(fetchConfiguration()),
      store.dispatch(fetchMenuItems()),
      store.dispatch(fetchManagerMenuItems()),
      store.dispatch(fetchCategories()),
      store.dispatch(fetchWorkingHours()),
      store.dispatch(fetchCoupons()),
    ]).then(() => {
      // After data refresh, show notification
      this.showGlobalNotification();
    });
  }

  // Show the global notification
  showGlobalNotification() {
    const message = 'התבצעו שינויים בהגדרות האתר, המידע עודכן באופן אוטומטי';
    console.log('Showing global notification:', message);

    // Dispatch the notification action
    store.dispatch({
      type: 'SHOW_GLOBAL_NOTIFICATION',
      payload: message,
    });
  }

  // Event handlers for specific update types
  handleConfigurationUpdate(data) {
    console.log('Configuration updated via socket:', data);
    this.refreshAllDataWithNotification();
  }

  handleMenuUpdate(data) {
    console.log('Menu updated via socket:', data);
    this.refreshAllDataWithNotification();
  }

  handleWorkingHoursUpdate(data) {
    console.log('Working hours updated via socket:', data);
    this.refreshAllDataWithNotification();
  }

  handlePricesUpdate(data) {
    console.log('Prices updated via socket:', data);
    this.refreshAllDataWithNotification();
  }

  handleCategoriesUpdate(data) {
    console.log('Categories updated via socket:', data);
    this.refreshAllDataWithNotification();
  }

  handleCouponsUpdate(data) {
    console.log('Coupons updated via socket:', data);
    this.refreshAllDataWithNotification();
  }

    handleGeneralUpdate(data) {
        console.log('General updated via socket:', data);
        this.refreshAllDataWithNotification();
    }

  handleCacheInvalidation(data) {
    console.log('Cache invalidated via socket:', data);
    this.refreshAllDataWithNotification();
  }

  // Cleanup function for unmounting
  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.connected = false;
    }
  }
}

// Create singleton instance
const socketService = new SocketService();

export default socketService;
