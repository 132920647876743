import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ordersApi from '../../api/ordersApi';

export const fetchOrderDetails = createAsyncThunk(
  'orders/fetchOrderDetails',
  async (_, { rejectWithValue }) => {
    try {
      return await ordersApi.getOrderDetails();
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrderDetailById = createAsyncThunk(
  'orders/fetchOrderDetailById',
  async (id, { rejectWithValue }) => {
    try {
      return await ordersApi.getOrderDetail(id);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrderWithDetails = createAsyncThunk(
  'orders/fetchOrderWithDetails',
  async (id, { rejectWithValue }) => {
    try {
      console.log(`Fetching details for order ID ${id}...`);
      const result = await ordersApi.getOrderWithDetails(id);

      if (result && result.details) {
        console.log(
          `Successfully loaded details for order ${id}. Found ${result.details.length} product items.`
        );

        if (result.details.length > 0) {
          console.log('First product item:', {
            menuName: result.details[0].menuName,
            price: result.details[0].price,
            quantity: result.details[0].quantity,
            id: result.details[0].id,
          });
        }
      } else {
        console.log(`Got response for order ${id} but no details found.`);
      }

      return result;
    } catch (error) {
      console.error(`Error fetching details for order ${id}:`, error);
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchOrdersDetails = createAsyncThunk(
  'orders/fetchOrdersDetails',
  async (orderIds, { dispatch, rejectWithValue }) => {
    try {
      console.log(`Fetching details for ${orderIds.length} orders...`);
      const results = [];

      for (const id of orderIds) {
        try {
          const result = await dispatch(fetchOrderWithDetails(id)).unwrap();
          results.push(result);
        } catch (err) {
          console.warn(`Failed to fetch details for order #${id}:`, err);
        }
      }

      return results;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createOrderDetail = createAsyncThunk(
  'orders/createOrderDetail',
  async (orderDetail, { rejectWithValue }) => {
    try {
      return await ordersApi.createOrderDetail(orderDetail);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateOrderDetail = createAsyncThunk(
  'orders/updateOrderDetail',
  async ({ id, orderDetail }, { rejectWithValue }) => {
    try {
      return await ordersApi.updateOrderDetail(id, orderDetail);
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteOrderDetail = createAsyncThunk(
  'orders/deleteOrderDetail',
  async (id, { rejectWithValue }) => {
    try {
      await ordersApi.deleteOrderDetail(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
