import React from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Button,
  ButtonGroup,
  Alert,
  Typography,
} from '@mui/material';
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts';

const OverviewTab = ({ data }) => {
  const theme = useTheme();
  // נשתמש בנתוני הקטגוריות במקום בנתוני המוצרים
  const { categoryData, dailyData } = data;

  // בדיקה אם יש נתוני קטגוריות
  const hasCategoryData = categoryData && categoryData.length > 0;
  // בדיקה אם יש נתוני מכירות יומיים
  const hasDailyData = dailyData && dailyData.some((day) => day.sales > 0);

  // טולטיפ מותאם לתצוגת קטגוריות
  const CategoryTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 2,
            border: '1px solid',
            borderColor: 'divider',
            borderRadius: 1,
            boxShadow: 2,
            minWidth: 180,
            direction: 'rtl',
          }}
        >
          <Typography variant="subtitle2" fontWeight="bold" color={data.color}>
            {data.name}
          </Typography>
          <Typography variant="body2">כמות: {data.value}</Typography>
          <Typography variant="body2">
            הכנסות: ₪{data.amount.toLocaleString()}
          </Typography>
          <Typography variant="body2">הזמנות: {data.orders}</Typography>
          {data.products && data.products.length > 0 && (
            <>
              <Typography
                variant="caption"
                fontWeight="bold"
                mt={1}
                display="block"
              >
                מוצרים בקטגוריה:
              </Typography>
              <Box component="ul" sx={{ m: 0, pl: 2, fontSize: '0.75rem' }}>
                {data.products.slice(0, 5).map((product, index) => (
                  <li key={index}>{product}</li>
                ))}
                {data.products.length > 5 && (
                  <Typography variant="caption">
                    + עוד {data.products.length - 5} מוצרים
                  </Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      );
    }
    return null;
  };

  // רינדור מותאם לתוויות בחלקי העוגה
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.7;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontWeight: 'bold', fontSize: '0.75rem' }}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="מכירות לפי קטגוריה"
            action={
              <Button size="small" color="primary" disabled={!hasCategoryData}>
                הצג הכל
              </Button>
            }
          />
          <CardContent>
            {!hasCategoryData ? (
              <Box
                sx={{
                  height: 350,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Alert severity="info">
                  אין נתוני קטגוריות זמינים. הנתונים יוצגו כאשר יהיו זמינים פרטי
                  הזמנות.
                </Alert>
              </Box>
            ) : (
              <Box sx={{ height: 350 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={categoryData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={120}
                      fill={theme.palette.primary.main}
                      dataKey="value"
                      nameKey="name"
                    >
                      {categoryData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip content={<CategoryTooltip />} />
                    <Legend
                      layout="vertical"
                      verticalAlign="middle"
                      align="right"
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={6}>
        <Card>
          <CardHeader
            title="מכירות לפי יום"
            action={
              <Button size="small" color="primary" disabled={!hasDailyData}>
                הצג הכל
              </Button>
            }
          />
          <CardContent>
            {!hasDailyData ? (
              <Box
                sx={{
                  height: 350,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Alert severity="info">
                  אין נתוני מכירות יומיים זמינים עדיין.
                </Alert>
              </Box>
            ) : (
              <Box sx={{ height: 350 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={dailyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => [`₪${value}`, 'מכירות']} />
                    <Legend />
                    <Bar
                      dataKey="sales"
                      name="מכירות (₪)"
                      fill={theme.palette.primary.main}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="מגמת מכירות"
            action={
              <ButtonGroup size="small">
                <Button>יומי</Button>
                <Button variant="contained">שבועי</Button>
                <Button>חודשי</Button>
              </ButtonGroup>
            }
          />
          <CardContent>
            {!hasDailyData ? (
              <Box
                sx={{
                  height: 350,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Alert severity="info">אין נתוני מגמות זמינים עדיין.</Alert>
              </Box>
            ) : (
              <Box sx={{ height: 350 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={dailyData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis
                      yAxisId="left"
                      orientation="left"
                      stroke={theme.palette.primary.main}
                    />
                    <YAxis
                      yAxisId="right"
                      orientation="right"
                      stroke={theme.palette.success.main}
                    />
                    <Tooltip />
                    <Legend />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="sales"
                      name="מכירות (₪)"
                      stroke={theme.palette.primary.main}
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="orders"
                      name="הזמנות"
                      stroke={theme.palette.success.main}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OverviewTab;
