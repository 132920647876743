import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showGlobalNotification: false,
  message: '',
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showGlobalNotification: (state, action) => {
      state.showGlobalNotification = true;
      state.message = action.payload;
    },
    hideGlobalNotification: (state) => {
      state.showGlobalNotification = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase('SHOW_GLOBAL_NOTIFICATION', (state, action) => {
      state.showGlobalNotification = true;
      state.message = action.payload;
    });
  },
});

export const { showGlobalNotification, hideGlobalNotification } =
  notificationSlice.actions;

export const selectShowGlobalNotification = (state) =>
  state.notification.showGlobalNotification;
export const selectGlobalNotificationMessage = (state) =>
  state.notification.message;

export default notificationSlice.reducer;
