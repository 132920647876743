import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

const calculateItemTotalPrice = (item) => {
  const basePrice = item.price;
  const selectedOptionsPrice = item.selectedValues.reduce((total, filter) => {
    return (
      total +
      filter.options.reduce((sum, option) => {
        return sum + parseFloat(option.filterOptionPrice || 0);
      }, 0)
    );
  }, 0);
  return (basePrice + selectedOptionsPrice) * item.quantity;
};

const recalculateDiscount = (state) => {
  if (state.coupon) {
    // Reset discount values
    state.discountILS = 0;
    state.discountPercentage = 0;

    if (state.coupon.type === 'percentage') {
      // For percentage coupons, use the direct percentage value
      state.discountPercentage = state.coupon.value;
      const discount = (state.totalPrice * state.coupon.value) / 100;
      state.discountILS = parseFloat(discount.toFixed(2));
      state.discountedPrice = Number((state.totalPrice - discount).toFixed(2));
    } else if (state.coupon.type === 'fixed') {
      // For fixed amount coupons, calculate the effective percentage
      state.discountILS = parseFloat(state.coupon.value);

      // Calculate the effective percentage this discount represents
      if (state.totalPrice > 0) {
        // Avoid division by zero
        state.discountPercentage = parseFloat(
          ((state.coupon.value / state.totalPrice) * 100).toFixed(2)
        );
      }

      state.discountedPrice = Number(
        (state.totalPrice - state.coupon.value).toFixed(2)
      );
      if (state.discountedPrice < 0) state.discountedPrice = 0;
    }
  } else {
    // If no coupon, reset all discount values
    state.discountILS = 0;
    state.discountPercentage = 0;
    state.discountedPrice = 0;
  }
};

const saveToLocalStorage = (state) => {
  localStorage.setItem('cartItems', JSON.stringify(state.items));
  if (state.coupon) {
    localStorage.setItem('cartCoupon', JSON.stringify(state.coupon));
    // Also save the discount values
    localStorage.setItem('cartDiscountILS', state.discountILS.toString());
    localStorage.setItem(
      'cartDiscountPercentage',
      state.discountPercentage.toString()
    );
    localStorage.setItem(
      'cartDiscountedPrice',
      state.discountedPrice.toString()
    );
  } else {
    localStorage.removeItem('cartCoupon');
    localStorage.removeItem('cartDiscountILS');
    localStorage.removeItem('cartDiscountPercentage');
    localStorage.removeItem('cartDiscountedPrice');
  }
};

const calculateTotals = (items) => {
  const totalQuantity = items.reduce((total, item) => total + item.quantity, 0);
  const totalPrice = Number(
    items.reduce((total, item) => total + item.totalPrice, 0).toFixed(2)
  );
  return { totalQuantity, totalPrice };
};

const generateFilterBasedId = (item) => {
  // Create a copy of selectedValues and sort each filter's options
  const sortedSelectedValues = [...item.selectedValues]
    .map((filter) => {
      return {
        filterId: filter.filterId,
        filterName: filter.filterName,
        // Sort options by ID to ensure consistent order
        options: [...filter.options].sort((a, b) =>
          a.filterOptionId.localeCompare(b.filterOptionId)
        ),
      };
    })
    // Sort filters by filterName
    .sort((a, b) => a.filterName.localeCompare(b.filterName));

  return `${item.id}_${JSON.stringify(sortedSelectedValues)}`;
};

const initialState = {
  items: JSON.parse(localStorage.getItem('cartItems')) || [],
  totalQuantity: 0,
  totalPrice: 0,
  coupon: null,
  discountedPrice: 0,
  discountILS: 0, // New field for ILS discount amount
  discountPercentage: 0, // New field for percentage discount value
};

if (initialState.items.length > 0) {
  const totals = calculateTotals(initialState.items);
  initialState.totalQuantity = totals.totalQuantity;
  initialState.totalPrice = totals.totalPrice;
}

// Then load and apply the coupon AFTER total price is calculated
if (localStorage.getItem('cartCoupon')) {
  try {
    const savedCoupon = JSON.parse(localStorage.getItem('cartCoupon'));
    if (savedCoupon) {
      // Check if coupon has expired
      if (savedCoupon.expiresAt) {
        const expiryDate = new Date(savedCoupon.expiresAt);
        const currentDate = new Date();

        if (currentDate > expiryDate) {
          // Coupon has expired, don't load it
          console.log(
            'Stored coupon has expired, removing from localStorage',
            savedCoupon
          );
          localStorage.removeItem('cartCoupon');
          localStorage.removeItem('cartDiscountILS');
          localStorage.removeItem('cartDiscountPercentage');
          localStorage.removeItem('cartDiscountedPrice');

          initialState.coupon = null;
          initialState.discountedPrice = 0;
          initialState.discountILS = 0;
          initialState.discountPercentage = 0;
        } else {
          // Coupon is still valid
          initialState.coupon = savedCoupon;

          // Try to load saved discount values first
          if (localStorage.getItem('cartDiscountILS')) {
            initialState.discountILS = parseFloat(
              localStorage.getItem('cartDiscountILS')
            );
          }
          if (localStorage.getItem('cartDiscountPercentage')) {
            initialState.discountPercentage = parseFloat(
              localStorage.getItem('cartDiscountPercentage')
            );
          }
          if (localStorage.getItem('cartDiscountedPrice')) {
            initialState.discountedPrice = parseFloat(
              localStorage.getItem('cartDiscountedPrice')
            );
          } else {
            // Calculate the discounted price if not available in localStorage
            if (savedCoupon.type === 'percentage') {
              initialState.discountPercentage = savedCoupon.value;
              const discount =
                (initialState.totalPrice * savedCoupon.value) / 100;
              initialState.discountILS = parseFloat(discount.toFixed(2));
              initialState.discountedPrice = Number(
                (initialState.totalPrice - discount).toFixed(2)
              );
            } else if (savedCoupon.type === 'fixed') {
              initialState.discountILS = parseFloat(savedCoupon.value);
              initialState.discountPercentage = 0;
              initialState.discountedPrice = Number(
                (initialState.totalPrice - savedCoupon.value).toFixed(2)
              );
              if (initialState.discountedPrice < 0)
                initialState.discountedPrice = 0;
            }
          }
        }
      } else {
        // No expiry date on coupon, assume it's valid
        initialState.coupon = savedCoupon;

        // Try to load saved discount values first
        if (localStorage.getItem('cartDiscountILS')) {
          initialState.discountILS = parseFloat(
            localStorage.getItem('cartDiscountILS')
          );
        }
        if (localStorage.getItem('cartDiscountPercentage')) {
          initialState.discountPercentage = parseFloat(
            localStorage.getItem('cartDiscountPercentage')
          );
        }
        if (localStorage.getItem('cartDiscountedPrice')) {
          initialState.discountedPrice = parseFloat(
            localStorage.getItem('cartDiscountedPrice')
          );
        } else {
          // Calculate discount values if not available
          if (savedCoupon.type === 'percentage') {
            initialState.discountPercentage = savedCoupon.value;
            const discount =
              (initialState.totalPrice * savedCoupon.value) / 100;
            initialState.discountILS = parseFloat(discount.toFixed(2));
            initialState.discountedPrice = Number(
              (initialState.totalPrice - discount).toFixed(2)
            );
          } else if (savedCoupon.type === 'fixed') {
            initialState.discountILS = parseFloat(savedCoupon.value);
            initialState.discountPercentage = 0;
            initialState.discountedPrice = Number(
              (initialState.totalPrice - savedCoupon.value).toFixed(2)
            );
            if (initialState.discountedPrice < 0)
              initialState.discountedPrice = 0;
          }
        }
      }

      console.log('Loaded coupon from localStorage:', initialState.coupon);
      console.log('Calculated discounted price:', initialState.discountedPrice);
      console.log('Discount ILS:', initialState.discountILS);
      console.log('Discount Percentage:', initialState.discountPercentage);
    }
  } catch (e) {
    console.error('Error loading coupon from localStorage', e);
    localStorage.removeItem('cartCoupon');
    localStorage.removeItem('cartDiscountILS');
    localStorage.removeItem('cartDiscountPercentage');
    localStorage.removeItem('cartDiscountedPrice');
  }
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const filterBasedId = generateFilterBasedId(newItem);
      const existingItem = state.items.find(
        (item) => item.filterBasedId === filterBasedId
      );

      if (existingItem) {
        existingItem.quantity += newItem.quantity;
        existingItem.totalPrice = calculateItemTotalPrice(existingItem);
      } else {
        newItem.uniqueId = uuidv4();
        newItem.filterBasedId = filterBasedId;
        newItem.totalPrice = calculateItemTotalPrice(newItem);
        state.items.push(newItem);
      }

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      // Recalculate discount after updating the cart
      recalculateDiscount(state);

      saveToLocalStorage(state);
      console.log('Cart after adding item:', state.items);
    },

    // updateCartItem reducer
    updateCartItem: (state, action) => {
      const updatedItem = { ...action.payload };
      const filterBasedId = generateFilterBasedId(updatedItem);
      const existingItemIndex = state.items.findIndex(
        (item) => item.uniqueId === updatedItem.uniqueId
      );

      if (existingItemIndex !== -1) {
        // Find if there's another item with the same filters
        const itemWithSameFilters = state.items.find(
          (item) =>
            item.filterBasedId === filterBasedId &&
            item.uniqueId !== updatedItem.uniqueId
        );

        if (itemWithSameFilters) {
          // Merge quantities if same filters found
          itemWithSameFilters.quantity += updatedItem.quantity;
          itemWithSameFilters.totalPrice =
            calculateItemTotalPrice(itemWithSameFilters);
          // Remove the original item
          state.items.splice(existingItemIndex, 1);
        } else {
          // Update existing item
          updatedItem.filterBasedId = filterBasedId;
          updatedItem.totalPrice = calculateItemTotalPrice(updatedItem);
          state.items[existingItemIndex] = updatedItem;
        }
      } else {
        // Add as new item if no existing item found
        updatedItem.uniqueId = updatedItem.uniqueId || uuidv4();
        updatedItem.filterBasedId = filterBasedId;
        updatedItem.totalPrice = calculateItemTotalPrice(updatedItem);
        state.items.push(updatedItem);
      }

      const totals = calculateTotals(state.items);
      state.totalQuantity = totals.totalQuantity;
      state.totalPrice = totals.totalPrice;

      // Recalculate discount after updating the cart
      recalculateDiscount(state);

      saveToLocalStorage(state);
      console.log('Cart after updating item:', state.items);
    },

    // removeFromCart reducer
    removeFromCart: (state, action) => {
      const uniqueId = action.payload.uniqueId;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem) {
        state.items = state.items.filter((item) => item.uniqueId !== uniqueId);
        const totals = calculateTotals(state.items);
        state.totalQuantity = totals.totalQuantity;
        state.totalPrice = totals.totalPrice;

        // Recalculate discount after updating the cart
        recalculateDiscount(state);
      }
      saveToLocalStorage(state);
      console.log('Cart after removing item:', state.items);
    },

    // decreaseFromCart reducer
    decreaseFromCart: (state, action) => {
      const uniqueId = action.payload.uniqueId;
      const existingItem = state.items.find(
        (item) => item.uniqueId === uniqueId
      );

      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity -= 1;
        existingItem.totalPrice = calculateItemTotalPrice(existingItem);
        const totals = calculateTotals(state.items);
        state.totalQuantity = totals.totalQuantity;
        state.totalPrice = totals.totalPrice;

        // Recalculate discount after updating the cart
        recalculateDiscount(state);
      }
      saveToLocalStorage(state);
      console.log('Cart after decreasing item quantity:', state.items);
    },

    // clearCart reducer
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.discountedPrice = 0;
      state.discountILS = 0;
      state.discountPercentage = 0;
      state.coupon = null; // Also clear the coupon when clearing the cart
      localStorage.removeItem('cartItems');
      localStorage.removeItem('cartCoupon'); // Remove coupon from localStorage
      localStorage.removeItem('cartDiscountILS');
      localStorage.removeItem('cartDiscountPercentage');
      localStorage.removeItem('cartDiscountedPrice');
      console.log('Cart after clearing:', state.items);
    },

    applyCoupon: (state, action) => {
      const coupon = action.payload;

      // Additional validation check on the reducer side
      if (coupon.expiresAt) {
        const expiryDate = new Date(coupon.expiresAt);
        const currentDate = new Date();

        if (currentDate > expiryDate) {
          // If coupon is expired, don't apply it
          console.log('Attempt to apply expired coupon blocked', coupon);
          return state;
        }
      }

      state.coupon = coupon;

      // Make sure totalPrice is up to date before calculating discount
      const totals = calculateTotals(state.items);
      state.totalPrice = totals.totalPrice;

      // Calculate discount based on coupon type and update both discount fields
      if (coupon.type === 'percentage') {
        state.discountPercentage = coupon.value;
        const discount = (state.totalPrice * coupon.value) / 100;
        state.discountILS = parseFloat(discount.toFixed(2));
        state.discountedPrice = Number(
          (state.totalPrice - discount).toFixed(2)
        );
      } else if (coupon.type === 'fixed') {
        state.discountILS = parseFloat(coupon.value);

        // Calculate the effective percentage this discount represents
        if (state.totalPrice > 0) {
          // Avoid division by zero
          state.discountPercentage = parseFloat(
            ((coupon.value / state.totalPrice) * 100).toFixed(2)
          );
        } else {
          state.discountPercentage = 0;
        }

        state.discountedPrice = Number(
          (state.totalPrice - coupon.value).toFixed(2)
        );
        // Ensure discounted price doesn't go below zero
        if (state.discountedPrice < 0) state.discountedPrice = 0;
      }

      saveToLocalStorage(state);
      console.log('Cart after applying coupon:', state);
    },

    removeCoupon: (state) => {
      state.coupon = null;
      state.discountedPrice = 0;
      state.discountILS = 0;
      state.discountPercentage = 0;
      saveToLocalStorage(state);
      console.log('Cart after removing coupon:', state);
    },
  },
});

export const {
  addToCart,
  updateCartItem,
  removeFromCart,
  decreaseFromCart,
  clearCart,
  applyCoupon,
  removeCoupon,
} = cartSlice.actions;

export const selectTotalQuantity = (state) => state.cart.totalQuantity;
export const selectTotalPrice = (state) => state.cart.totalPrice;
export const selectCartItems = (state) => state.cart.items;
export const selectCoupon = (state) => state.cart.coupon;
export const selectDiscountedPrice = (state) => state.cart.discountedPrice;
export const selectDiscountILS = (state) => state.cart.discountILS;
export const selectDiscountPercentage = (state) =>
  state.cart.discountPercentage;

export default cartSlice.reducer;
