import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';

const ProductsTab = ({ data }) => {
  const theme = useTheme();
  const { productData, summaryData } = data;
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // בדיקה אם יש נתוני מוצרים
  const hasProductData = productData && productData.length > 0;

  // סינון מוצרים לפי חיפוש
  const filteredProducts = !searchTerm
    ? productData
    : productData.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  if (!hasProductData) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="ביצועי מוצרים" />
            <CardContent>
              <Box
                sx={{
                  p: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Alert severity="info" sx={{ width: '100%' }}>
                  <Typography variant="body1" align="center">
                    אין נתוני מוצרים זמינים. המידע יוצג כאשר יהיו זמינים פרטי
                    הזמנות.
                  </Typography>
                  <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                    בדוק שהזמנות נטענו עם פרטיהן מה-API.
                  </Typography>
                </Alert>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Card>
          <CardHeader
            title="ביצועי מוצרים"
            action={
              <TextField
                placeholder="חיפוש מוצרים"
                size="small"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: 200 }}
              />
            }
          />
          <CardContent>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="right">מוצר</TableCell>
                    <TableCell align="right">כמות מכירות</TableCell>
                    <TableCell align="right">הזמנות</TableCell>
                    <TableCell align="right">הכנסות</TableCell>
                    <TableCell align="right">% מסך המכירות</TableCell>
                    <TableCell align="right">פרטים</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProducts.map((product, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:hover': {
                          bgcolor: 'action.hover',
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleProductClick(product)}
                    >
                      <TableCell align="right">{product.name}</TableCell>
                      <TableCell align="right">{product.value}</TableCell>
                      <TableCell align="right">
                        {product.orders || '-'}
                      </TableCell>
                      <TableCell align="right">
                        ₪{product.amount.toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {summaryData.totalSales > 0
                          ? Math.round(
                              (product.amount / summaryData.totalSales) * 100
                            )
                          : 0}
                        %
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="הצג פרטים נוספים">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleProductClick(product);
                            }}
                          >
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {filteredProducts.length === 0 && (
              <Box sx={{ p: 2, textAlign: 'center' }}>
                <Typography color="text.secondary">
                  לא נמצאו מוצרים התואמים לחיפוש
                </Typography>
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="התפלגות מכירות" />
          <CardContent>
            <Box sx={{ height: 250 }}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={productData.slice(0, 5)} // מציג רק את 5 המוצרים המובילים
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill={theme.palette.primary.main}
                    dataKey="amount"
                    nameKey="name"
                    label={false}
                  >
                    {productData.slice(0, 5).map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <RechartsTooltip
                    formatter={(value) => [`₪${value.toFixed(2)}`, 'הכנסות']}
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
            {productData.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  מוצר מוביל
                </Typography>
                {(() => {
                  // מציאת המוצר עם ההכנסות הגבוהות ביותר
                  const topProduct = [...productData].sort(
                    (a, b) => b.amount - a.amount
                  )[0];

                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          bgcolor: topProduct.color,
                          mr: 1,
                        }}
                      />
                      <Typography variant="body2" fontWeight="medium">
                        {topProduct.name} -{' '}
                        {summaryData.totalSales > 0
                          ? Math.round(
                              (topProduct.amount / summaryData.totalSales) * 100
                            )
                          : 0}
                        % מסך המכירות
                      </Typography>
                    </Box>
                  );
                })()}
              </Box>
            )}
          </CardContent>
        </Card>
      </Grid>

      {/* דיאלוג פרטי מוצר */}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        {selectedProduct && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    bgcolor: selectedProduct.color,
                    mr: 1.5,
                  }}
                />
                {selectedProduct.name}
              </Box>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    כמות מכירות
                  </Typography>
                  <Typography variant="h6">{selectedProduct.value}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    הזמנות
                  </Typography>
                  <Typography variant="h6">
                    {selectedProduct.orders || '-'}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    הכנסות
                  </Typography>
                  <Typography variant="h6">
                    ₪{selectedProduct.amount.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="text.secondary">
                    אחוז מסך המכירות
                  </Typography>
                  <Typography variant="h6">
                    {summaryData.totalSales > 0
                      ? Math.round(
                          (selectedProduct.amount / summaryData.totalSales) *
                            100
                        )
                      : 0}
                    %
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>סגור</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Grid>
  );
};

export default ProductsTab;
