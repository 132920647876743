import axios from 'axios';

/* eslint-disable no-unused-vars */
const render_URL = 'https://api.olivercoffeecart.online/api/unified-file';
const local_URL = 'http://localhost:3001/api/unified-file';
/* eslint-enable no-unused-vars */
const BASE_URL =
  process.env.REACT_APP_NODE_ENV === 'production' ? render_URL : local_URL;

// Create axios instance with credentials
const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

// Get unified files by date - generates files on the server
export const getUnifiedFile = async (parameters) => {
  const response = await api.get(BASE_URL, {
    params: parameters, // parameters will be automatically converted to query string
  });
  return response.data;
};

// Download unified files directly - returns blob for browser download
export const downloadUnifiedFile = async ({ startDate, endDate }) => {
  const response = await api.post(
    `${BASE_URL}/download`,
    { startDate, endDate },
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  return response;
};
