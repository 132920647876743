import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Typography, Paper, Fade } from '@mui/material';
import {
  selectShowGlobalNotification,
  selectGlobalNotificationMessage,
  hideGlobalNotification,
} from '../../../features/notification/notificationSlice';

const GlobalNotification = () => {
  const dispatch = useDispatch();
  const showNotification = useSelector(selectShowGlobalNotification);
  const message = useSelector(selectGlobalNotificationMessage);

  // Automatically hide after a timeout
  useEffect(() => {
    let timer;
    if (showNotification) {
      // Auto-dismiss after 5 seconds
      timer = setTimeout(() => {
        dispatch(hideGlobalNotification());
      }, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [showNotification, dispatch]);

  if (!showNotification) return null;

  return (
    <Fade in={showNotification}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          zIndex: 9999,
        }}
      >
        <Paper
          elevation={6}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
            padding: '24px 32px',
            textAlign: 'center',
            borderRadius: '8px',
            maxWidth: '80%',
            boxShadow: '0 4px 20px rgba(0,0,0,0.3)',
          }}
        >
          <Typography
            variant="h5"
            component="div"
            dir="rtl"
            sx={{
              fontWeight: 'medium',
              fontSize: { xs: '1.1rem', sm: '1.3rem' },
            }}
          >
            {message}
          </Typography>
        </Paper>
      </Box>
    </Fade>
  );
};

export default GlobalNotification;
