import React from 'react';
import { TextField, InputAdornment, useTheme } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

/**
 * Simple RTL Date Input Component
 * - Shows only bottom border
 * - Calendar icon on the left side
 * - Input text on the right side
 * - Dark mode responsive
 */
const RTLDateInput = ({ label, value, onChange, ...props }) => {
  const theme = useTheme();

  return (
    <TextField
      label={label}
      type="date"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputLabelProps={{
        shrink: true,
        sx: {
          right: 0,
          left: 'auto',
          transformOrigin: 'right top',
          color:
            theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.7)'
              : 'rgba(0, 0, 0, 0.6)',
        },
      }}
      InputProps={{
        sx: {
          direction: 'rtl',
          '& input': { textAlign: 'right' },
        },
        endAdornment: (
          <InputAdornment position="end">
            <CalendarTodayIcon
              sx={{
                color:
                  theme.palette.mode === 'dark'
                    ? 'white'
                    : 'rgba(0, 0, 0, 0.54)',
                marginRight: 'auto',
                display: 'none', // Hide the default icon as it's already provided by the date input
              }}
            />
          </InputAdornment>
        ),
      }}
      variant="standard"
      fullWidth
      required
      sx={{
        '& .MuiInput-root': {
          borderBottom: `1px solid ${
            theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.3)'
              : 'rgba(0, 0, 0, 0.23)'
          }`,
          '&:before, &:after': {
            borderBottom: 'none !important',
          },
          '&:hover:not(.Mui-disabled)': {
            borderBottom: `1px solid ${
              theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, 0.6)'
                : 'rgba(0, 0, 0, 0.6)'
            }`,
            '&:before': {
              borderBottom: 'none !important',
            },
          },
          '&.Mui-focused': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
          },
        },
        '& input[type="date"]::-webkit-calendar-picker-indicator': {
          filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none',
        },
      }}
      {...props}
    />
  );
};

export default RTLDateInput;
